import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (

        <div>
        
         
        <footer>
        <div className="container">
          <ul className="footer_pdf">
              <li>
                <a target="_blank" href="pdf/Finsso_vietnamese.pdf">
                  <img src="img/ppt.png" alt="" />
                 <span>Vietnamese PPT</span> 
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Finsso_hindi.pdf">
                <img src="img/ppt.png" alt="" />
                <span>Hindi PPT</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Finsso_English.pdf">
                <img src="img/ppt.png" alt="" />
                  <span>English PPT</span>
                </a>
              </li> 
              <li>
                <a target="_blank" href="video/English-Presentation.mp4">
                  <img src="img/video.png" alt="" />
                  <span>English video presentation</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="video/Hindi-Presentaion.mp4">
                  <img src="img/video.png" alt="" />
                  <span>Hindi video presentation</span>
                </a>
              </li>
            </ul>
          </div>


          <div className="container">
            <div className="row">
              <div class="col-md-12 middle_text">
                <p>&copy; 2021 finsso.org All Rights Reserved. </p>
              </div>
            </div>
          </div>
      </footer>

      {/* go to tp */}
      <a href="javascript:" id="return-to-top"><i className="fa fa-arrow-up"></i></a>
      {/* go to tp */}

      {/* loader */}
      <div className="overlay">
        <div className="overlayDoor"></div>
          <div className="overlayContent">
              <img src="img/logo.png" alt="" />
              <p>Loading.....</p>
          </div>
      </div>
       {/* loader */}
     
    </div>

      );
    }
  }

  export default Footer;