import React, { Component } from 'react';
import Frame from "../../components/frame";
import Joinnow from "./joinnow";
import Form from "./form";
import Pools from "./pools";
import {withRouter,Link} from "react-router-dom";
import Finsso from "../../contracts/Finsso.json";
import getWeb3 from "../../getWeb3";

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        storageValue: 0,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        poolDeposit:[0,0,0],
        poolIncome:[0,0,0],
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        referral_count:[0,0,0,0,0,0],
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
      }      
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = Finsso.networks[networkId];
          const instance = new web3.eth.Contract(
            Finsso.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      let poolDeposit = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.pool_deposits(i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolDeposit.push(tempVal);
      }

      let poolIncome = [];
      for(let i=0;i<3;i++){
        let tempVal = await contract.methods.user_bonus_pool(accounts,i).call();
        tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
        poolIncome.push(tempVal);
      }

      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      let totalDeposits = await contract.methods.totalInvested().call();
      totalDeposits = this.state.web3.utils.fromWei(totalDeposits, 'ether');

      let referral_count = [];
      for(let i=0;i<6;i++){
        let tempVal = await contract.methods.referral_count(accounts,i).call();
        referral_count.push(tempVal);
      }      

      this.setState({ 
        userInfo,
        poolDeposit,
        poolIncome,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        referral_count,
        networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
      });

     
    }

    render() {
      return (
        <Frame withHeader={true} withFooter={true} showEnter={true}>

          <Joinnow props={this.props} state={this.state} fetchData={this.fetchData}></Joinnow>
          
          {/* Banner */}
          <div className="banner_section middle_text">
            <div className="container">
              <div className="banner_text">
                <div className="text-center pb_30">
                  <h1>FOREX SIGNALS DETERMINES THE RIGHT OPPORTUNITIES AT THE RIGHT MOMENT</h1>
                 </div>

                {/* <Form></Form> */}
               
              </div>
            </div>  
          </div>
           {/* Banner */}


           <section id="about">
            <div className="container">
              <div className="banner_box">
                
                <div className="row">
                  <div className="col-md-8 col-sm-7 middle_text">
                    <p className="text_line_height">Forex signals serve to determine the right forex trading opportunities at the right moment. By its very nature, a Forex signal refers to a trading idea centered around a particular currency pair, which should be implemented at a predefined price and time.</p>
                
                  </div>
                  <div className="col-md-4 col-sm-5">
                    <img class="img-fluid mobile_none" src="img/image1.png" alt="" />
                  </div>
                </div>

              </div>
            </div>
          </section>

          <br />

          <section class="pt_60 pb_60 box_bg">
            <div class="container">
              <div class="text-center">
                 <p class="text_line_height">There are so many brilliant organization and software who provides a great signal for trading in forex as well in crypto currency market. Good signals are paid. Finsso comes with solution you will receive a free signal for all Market including crypto currency, forex and stock. Finsso is a decentralized network model in which we all who has same interest comes over commonly known BSC chain smart contract software gives us a chance to associate together with 100% transparency and with 100% accuracy in relationship, this is people ' s startup.</p>
              </div>
              
            </div>
          </section>

          <section className="pb_60 pt_60">
            <div className="container">
            
            <div className="row">
              <div className="col-md-4 col-sm-5">
                <img className="img-fluid" src="img/image2.png" alt="" />
              </div>
              <div className="col-md-8 col-sm-7 middle_text">
               
                <p className="text_line_height">Best known for running smart contract-based applications Binance Smart Chain is a blockchain network that runs along with Binance's native chain or BC. The fast transaction capability helps the network users with faster and loads of transactions at any time simply by using the smart contract functionality of BSC.</p>
                
                </div>
              
            </div>
          
            </div>
          </section>

          <hr />

          <Pools></Pools>

         {/* Features */}
        <section className="form_bg" id="Features">
          <div className="container">
            <div className="box_shadow">
            <div className="row">
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/friends.jpg" alt="" />
              </div>
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>WHAT IS FINSSO.ORG?</h2>
                  </div>
                  <p className="mb-0">If you are looking for the right platform offering the best forex trading opportunities, Finsso is for you. Finsso provides you a solution to receive free forex signals for all markets which includes cryptocurrency, stock and forex. Adhering to the decentralized network model, Finsso provides you 100% transparency and security, powered by Binance Smart Chain algorithm.</p>
                </div>
              </div>
            </div>
        
            </div>
            <div className="box_shadow">
            <div className="row">
              
              <div className="col-md-7 col-sm-7 middle_text">
                <div className="box_padding">
                  <div className="ext_heading">
                    <h2>What is BNB coin?</h2>
                  </div>
                  <p className="mb-0">
                  Binance Coin is the cryptocurrency issued by the Binance exchange and trades with the BNB symbol.
                  BNB was initially based on the Ethereum network but is now the native currency of Binance's own blockchain, the Binance chain.
                  Every quarter, Binance uses one-fifth of its profits to repurchase and permanently destroy, or "burn," Binance coins held in its treasury.
                  Binance was created as a utility token for discounted trading fees in 2017, but its uses have expanded to numerous applications, including payments for transaction fees (on the Binance Chain), travel bookings, entertainment, online services, and financial services.
                  At the time of writing, Binance had a market cap of $88,276,993,118 and ranks behind only Bitcoin and Ethereum in terms of size.
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-sm-5">
                <img className="img-fluid" src="img/bnb-coin.jpg" alt="" />
              </div>
            </div>
        
            </div>
        
          </div>
        </section>
         {/* Features */}


        {/* Faq */}
         <section className="pb_60" id="faq">
          <div className="container">
            <div className="all_heading text-center">
              <h2>All your  <span>queries answered!</span></h2>
            </div>

            <div className="faq_inner">
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  What is possible on the Binance chain?
                </div>
                <div className="accordion_body" style={{display: "block"}}>
                  <p>In terms of sending or receiving coins and decentralized donations or gifts, the Binance Chain is the perfect place or platform best utilized. Apart from the exchange of coins, minting tokens, issuance of new tokens is also possible which is done as per the terms of creation of trading pairs between two types of tokens and also the same pairs as well. You can even buy digital assets or cryptos using the trade pairs created on the platform.</p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How a digital wallet is configured for BSC?
                </div>
                <div className="accordion_body">
                  <p> When it comes to the storage of cryptocurrencies, digital wallets are the safest place and for this reason, BSC has kept the wallet configuration process simple and easy. All you need to do is set it up on Trust Wallet computer browser or a mobile app. Once the configuration is completed following the instructions, the Trust wallet gets activated for depositing cryptos and making withdrawals as well.   </p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How do I exchange and issue coins on BSC?
                </div>
                <div className="accordion_body">
                  <p> Exchange and issuance of coins on BSC are the same as the process applicable with other cryptos. BNB coin is used for making payments and trading and it runs on Ethereum blockchain and is issued by the Binance exchange with BNB symbol engraved on it. The coin is exchangeable with another cryptocurrency like Bitcoin. But there is a limiting cap of 200 million tokens for running on the Ethereum blockchain and using ERC20. </p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How Binance Smart Chain function?
                </div>
                <div className="accordion_body">
                  <p> The platform is backed by Proof-of-Stake (POS) consensus algorithm and works accordingly, but even makes use of Proof-of-Staked Authority or PoSA consensus as well. This means that the network participants are allowed to stake and serve as proper validators and transaction fees are only released when a block gets verified and validated on the network the way it is meant to be. </p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How does one receive BNB on BSC?
                </div>
                <div className="accordion_body">
                  <p> BNB receiving on BSC is kept simple to understand for all and can be received in form of BNB on BSC or get BNB delivered directly from a Trust wallet. You can even receive BNB simply by withdrawing the BSC credentials from Binance using the smart chain address of an active Trust wallet.  </p>
                </div>
              </div>
              <div className="accordion_border">
                <div className="accordion_head">
                  <span className="plusminus"><i className="fa fa-angle-down"></i></span>
                  How do I Metamask with BSC?
                </div>
                <div className="accordion_body">
                  <p> Metamask is generally created on Ethereum-based websites which is more secure and can be utilized easily as well. When it comes to connecting both, a browser extension will get the job done and can be downloaded & installed on a computer or available in app form (Google Play & iOS) if you are a mobile user. In the last step, all you need to do is create a wallet that can help in sending and receiving capital. </p>
                </div>
              </div>
            
            
            </div>
          </div>
        </section> 
         {/* Faq */}

        </Frame>
      );
    }
  }

  export default withRouter(Home);
  //export default Home;