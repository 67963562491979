import React, { Component } from 'react';
import Frame from "../../components/frame";
import Joinnow from "../home/joinnow";
import {withRouter,Link} from "react-router-dom";
import Bnbfriends from "../../contracts/Bnbfriends.json";
import getWeb3 from "../../getWeb3";

class Guide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storageValue: 0,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},
      poolDeposit:[0,0,0],
      poolIncome:[0,0,0],
      TotalBonus:0,
      totalWithdrawn:0,
      totalDeposits:0,
      referral_count:[0,0,0,0,0,0],
      networkMain:false,
      YourAddress: '-',
      YourAddressfull: null,
    }      
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = Bnbfriends.networks[networkId];
        const instance = new web3.eth.Contract(
          Bnbfriends.abi,
          deployedNetwork && deployedNetwork.address,
        );   
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    const { accounts, contract } = this.state;

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;
    let userInfo = await contract.methods.users(accounts).call();
    userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
    userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
    let poolDeposit = [];
    for(let i=0;i<3;i++){
      let tempVal = await contract.methods.pool_deposits(i).call();
      tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
      poolDeposit.push(tempVal);
    }

    let poolIncome = [];
    for(let i=0;i<3;i++){
      let tempVal = await contract.methods.user_bonus_pool(accounts,i).call();
      tempVal = this.state.web3.utils.fromWei(tempVal, 'ether');
      poolIncome.push(tempVal);
    }

    let TotalBonus = await contract.methods.TotalBonus(accounts).call();
    TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
    let totalWithdrawn = await contract.methods.totalWithdrawn().call();
    totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
    let totalDeposits = await contract.methods.totalDeposits().call();

    let referral_count = [];
    for(let i=0;i<6;i++){
      let tempVal = await contract.methods.referral_count(accounts,i).call();
      referral_count.push(tempVal);
    }      

    this.setState({ 
      userInfo,
      poolDeposit,
      poolIncome,
      TotalBonus,
      totalWithdrawn,
      totalDeposits,
      referral_count,
      networkMain:true,
      YourAddress:YourAddress,
      YourAddressfull:YourAddressfull,
    });

   
  }
    render() {
      return (
        <Frame withHeader={true}  withFooter={true} showEnter={true}>
          <Joinnow props={this.props} state={this.state} fetchData={this.fetchData}></Joinnow>
          <div className="banner_section middle_text">
            <div class="banner_text">
              <div class="text-center pb_30">
                <h1>Customer Guide</h1>
                </div>
              </div>
          </div>
          
         
        <div className="">
          <div className="container">
          <div className="banner_box">
          <ul className="footer_pdf">
              <li>
                <a target="_blank" href="pdf/Finsso_vietnamese.pdf">
                  <img src="img/ppt.png" alt="" />
                 <span>Vietnamese PPT</span> 
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Finsso_hindi.pdf">
                <img src="img/ppt.png" alt="" />
                <span>Hindi PPT</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Finsso_English.pdf">
                <img src="img/ppt.png" alt="" />
                  <span>English PPT</span>
                </a>
              </li> 
              <li>
                <a target="_blank" href="video/English-Presentation.mp4">
                  <img src="img/video.png" alt="" />
                  <span>English video presentation</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="video/Hindi-Presentaion.mp4">
                  <img src="img/video.png" alt="" />
                  <span>Hindi video presentation</span>
                </a>
              </li>
            </ul>
          </div>
          </div>
        </div>

        <br />
          <section className="pb_60 guide_img" id="about">
            

            <div className="container">
              <div className="md_container">
                <h2>Installing and setting up MetaMask</h2>
                <p>MetaMask can be downloaded on Chrome and Firefox, or on iOS and Android if you’re a mobile user. For the purposes of this tutorial, we’ll use the Firefox version, but the instructions will be more or less the same for every platform.</p>
                <p>Firstly, you’ll want to head over to the MetaMask<a className="academy_link" href="https://metamask.io/download.html"> Download page</a>. From there, select whichever platform you’re using, and follow the steps to install it on your device. Easy!</p>
                <p>Next, follow along with the setup specified by the app. Go ahead and click Create a Wallet. Write down the backup <a className="academy_link" href="https://academy.binance.com/en/glossary/seed-phrase">seed phrase</a> somewhere secret (preferably not on an Internet-connected device). Without this phrase, your funds can’t be recovered if your device is damaged or lost. Confirm that you’ve written them down on the next page.</p>
                <p>And that’s it! You should now see your wallet, ready to send and receive funds.</p>
                <img className="acdemy_image" src="img/image.webp" alt="img" />
                <p className="academy_image_text">The freshly-initialized MetaMask wallet.</p>
                <h2>Configuring the wallet</h2>
                <p>You might notice straight away that we’re still dealing with an <a className="academy_link" href="https://academy.binance.com/en/articles/what-is-ethereum">Ethereum</a> wallet. At best, this won’t work with Binance Smart Chain DApps. At worst, you could lose funds by sending them to addresses you can’t actually use.</p>
                <p>Let’s change that. We want to access the Settings to point the wallet towards Binance Smart Chain nodes.</p>
                <img className="acdemy_image" src="img/image01.webp" alt="img" />
                <p className="academy_image_text">Select Settings from the dropdown menu..</p>
                <p>On the Settings page, we want to locate the Networks menu.</p>
                <img className="acdemy_image" src="img/image02.webp" alt="img" />
                <p className="academy_image_text">The Networks menu.</p>
                <p>We want to click Add Network in the top-right corner to manually add the Binance Smart Chain one – it doesn’t come packaged with MetaMask. It’s important to note that there are two networks we can use here: the testnet or the mainnet. Below are the parameters to fill in for each.</p>
                <h4>Mainnet (This is the one you are probably looking for)</h4>
                <br />
                <p><strong>Network Name:</strong> Smart Chain</p>
                <p><strong>ChainID:</strong> 56</p>
                <p><strong>Symbol:</strong> BNB</p>
                <p><strong>New RPC URL:</strong> https://bsc-dataseed.binance.org/</p>
                <p><strong>Block Explorer URL:</strong> https://bscscan.com</p>
             
              </div>
            </div>

            <div className="container">
              <div className="md_container">
                {/* <h1>How to Send and Receive BNB on Smart Chain</h1> */}
                <h2>What is Binance Smart Chain?</h2>
                <p><a className="academy_link" href="https://www.binance.org/en/smartChain">Binance Smart Chain (BSC) </a>
                <span className="badge badge-notification clicks" title="1065 clicks">1.1k</span> is an <b>EVM-compatible network</b> , forked from “go-ethereum”. It supports smart contracts and relies on a new consensus mechanism: <b>Proof-of-Staked Authority (PoSA) consensus (“Parlia”)</b> ,
                 which incorporates elements from both Proof of <a className="academy_link" href="https://trustwallet.com/earn"> Stake </a>
                  and Proof of Authority. The Binance Chain community launched BSC in time for the DeFi 
                  (decentralized finance) revolution, as the public shows increased interest in alternative financial solutions powered by blockchain.
                </p>
                <p>
                In simple terms, it is a blockchain that runs in parallel with Binance Chain. 
                <a className="academy_link" href="https://www.binance.org/en/smartChain"> BNB </a>
                <span className="badge badge-notification clicks" title="130 clicks"> 130 </span> is used for delegated                   
                  <a className="academy_link" href="https://trustwallet.com/earn"> staking </a>
                   on the authority validator, leading to staking rewards for users and validators. 
                   <a className="academy_link" href="https://www.binance.com/en/blog/421499824684900933/Binance-Smart-Chain-Launches-Today"> BSC is live on mainnet </a>
                   <span className="badge badge-notification clicks" title="192 clicks"> 192 </span>                   
                    , ready to be tested and piloted by some of the top projects in the crypto industry, is expected to greatly increase the utility of both Binance Chain and BNB.
                </p>
                <h2>Trust Wallet Supports Binance Smart Chain</h2>
                <p>
                Immediately after BSC mainnet was launched,
                <a className="academy_link" href="https://trustwallet.com/"> Trust Wallet </a> 
                <span className="badge badge-notification clicks" title="687 clicks"> 687 </span> added
                  support for the network. You can already send and receive BNB using your <b>Smart Chain</b> address.
                  Just make sure to install the latest update available for your device. There will be a lot of exciting features that will soon be enabled within the app to support BSC, so stay tuned.
                <br />
                For now, we will show you how you can easily move your BNB tokens on Binance Smart Chain.
                </p>
                <h2>Receiving BNB on BSC</h2>
                <p>As of the moment, there are only 2 ways to get BNB on your Smart Chain address:</p>
                <div className="pool_box" style={{padding: 0, paddingLeft:15, paddingRight:15, textAlign:'left'}}>
                  <blockquote style={{padding: 12}}>
                    <ul style={{marginBottom: 0}}>
                      <li>
                          <a className="academy_link" href="https://community.trustwallet.com/t/receiving-cryptocurrencies/77/4">Receive <span class="badge badge-notification clicks" style={{display:'initial'}} title="1375 clicks">1.4k</span></a> BNB from another <strong>Trust Wallet</strong> user
                      </li>
                      <li>
                        Withdraw to a BSC address from <a className="academy_link" href="http://Binance.com">Binance.com <span class="badge badge-notification clicks" style={{display:'initial'}} title="1698 clicks">1.7k</span></a>
                      </li>
                    </ul>
                  </blockquote>                  
                </div>
                <p>The first one is self-explanatory, so we will focus on the second option. You can already withdraw your BNB from a Binance exchange account to your <strong>Trust Wallet</strong> Smart Chain address. Here is the official 
                <a className="academy_link" href="https://www.binance.com/en/support/articles/7dd6f73d719b4ca3a296a468a5607ac4"> announcement <span class="badge badge-notification clicks" title="1058 clicks">1.1k</span></a>.
                </p>
                <h2>Get the Smart Chain address</h2>
                <p>Search for <strong>Smart Chain</strong> and <a className="academy_link" href="https://community.trustwallet.com/t/how-to-add-or-remove-a-coin/896/2"> enable <span class="badge badge-notification clicks" title="3897 clicks">3.9k</span></a> it on the app. Then, tap on Receive to see the address. Tap on Copy to save the address on your device clipboard.</p>
                <div className="row" style={{marginBottom: '1rem'}}>
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile1.jpeg" alt="img" />
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile2.jpeg" alt="img" />
                </div>
                <h2>Withdraw BNB to BSC</h2>
                <p>On your Binance account, open your <strong>BNB</strong> wallet then tap on Withdraw. Select <strong>BEP20</strong> as the <strong>Network</strong>. Indicate the amount and complete the steps to withdraw.</p>
                <div className="row" style={{marginBottom: '1rem'}}>
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile3.jpeg" alt="img" />
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile4.jpeg" alt="img" />
                </div>
                <p>Wait for the exchange to process your request. Once it is confirmed, you will immediately receive BNB to your Smart Chain address.</p>
                <div className="row" style={{marginBottom: '1rem'}}>
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile5.jpeg" alt="img" />
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile6.jpeg" alt="img" />
                </div>
                <h2>Sending BNB from a Smart Chain address</h2>
                <p>BNB that is on your Smart Chain address can only be <a className="academy_link" href="https://community.trustwallet.com/t/sending-cryptocurrencies/65/4"> sent <span class="badge badge-notification clicks" title="112 clicks">112</span></a> to another Smart Chain address. Please make sure that you are sending to a Smart Chain address so that the funds are <span class="hashtag">#SAFU</span>. There will be no issues sending BNB to a Trust Wallet user, as it is fully supported. But in case you want to trade your BNB, for now, you can send them to your Binance account.</p>
                <p>Here is the procedure:</p>
                <h2>Get the Deposit Address on Binance</h2>
                <p>You need to select the <strong>BEP20</strong> Network for the <strong>BNB</strong> Deposit address and then <strong>Copy</strong> the address.</p>
                <div className="row" style={{marginBottom: '1rem'}}>
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile7.jpeg" alt="img" />
                </div>
                <h2>Prepare to send BNB</h2>
                <p>Open your <strong>Smart Chain</strong> wallet on <strong>Trust Wallet</strong> and then tap on <strong>Send</strong>. Fill up the details, put the <strong>BSC Network</strong> address and the amount. Tap on Continue.</p>
                <div className="row" style={{marginBottom: '1rem'}}>
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile8.jpeg" alt="img" />
                </div>
                <p>Double check the transaction before you hit Send. The BNB tokens will be sent out right away.</p>
                <div className="row" style={{marginBottom: '1rem'}}>
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile9.jpeg" alt="img" />
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile10.jpeg" alt="img" />
                </div>
                <h2>Wait for Confirmation</h2>
                <p>Since you are sending BNB to an exchange, there will be a required number of confirmations before your deposit is shown. If you have correctly put the right deposit address then you are good to go.</p>
                <div className="row" style={{marginBottom: '1rem'}}>
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile11.jpeg" alt="img" />
                  <img class="acdemy_image col-md-4" src="img/bnb-mobile12.jpeg" alt="img" />
                </div>
                <h2>How to Check BSC Transactions?</h2>
                <p><a className="academy_link" href="https://bscscan.com/"> <strong>BscScan</strong> <span class="badge badge-notification clicks" title="387 clicks">387</span></a>, the block explorer for <strong>Binance Smart Chain (BSC)</strong> was created by the Etherscan team, and it supports both BSC <a className="academy_link" href="https://bscscan.com/">mainnet <span class="badge badge-notification clicks" title="387 clicks">387</span></a> and <a className="academy_link" href="https://testnet.bscscan.com/">testnet <span class="badge badge-notification clicks" title="49 clicks">49</span></a>. It has many features that is similar with <a className="academy_link" href="https://etherscan.io/">Etherscan <span class="badge badge-notification clicks" title="28 clicks">28</span></a>.</p>
              </div>
            </div>


          </section>

        </Frame>
      );
    }
  }

  export default withRouter(Guide);
  //export default Guide;